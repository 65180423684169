@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------ custom scrollbar -------- */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #222222;
}

::-webkit-scrollbar-thumb {
  background-color: #ff4d05;
  border-radius: 100px;
}

::-moz-scrollbar {
  width: 5px;
}

::-moz-scrollbar-track {
  background-color: #222222;
}

::-moz-scrollbar-thumb {
  background-color: #ff4d05;
  border-radius: 100px;
}

/* For Internet Explorer */
::-ms-scrollbar {
  width: 5px;
}

::-ms-scrollbar-track {
  background-color: #222222;
}

::-ms-scrollbar-thumb {
  background-color: #ff4d05;
  border-radius: 100px;
}
